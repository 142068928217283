/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { addDays, differenceInCalendarDays, format, isBefore, parse } from 'date-fns';

import { FORMATTING_CONSTANTS } from '@hosty-app/core';
import { ICheckInOutReservation } from '@hosty-app/types/interfaces';

import { convertChannelFromDto, convertChannelToDto } from '@hosty-web/utils';

import {
  EAlterationStatus,
  ECancelBy,
  EChannel,
  EReservationPaymentStatus,
  EReservationStatus,
  EReservationStatusTitle,
  EReservationType,
} from '../enums';
import { IReservation, IReservationAlteration, IReservationReview } from '../interfaces';

import { Client } from './client';
import { convertDateFromDto, convertDateToDto } from './date';
import { PropertyInfo } from './property-info';
import { RevenueSplit } from './revenue-split';
import { SpecialOffer } from './special-offer';

export class ReservationReview {
  public categoryRatings: {
    accuracy: number;
    checkin: number;
    cleanliness: number;
    communication: number;
    id: number;
    location: number;
    respectHouseRules: number;
    value: number;
  };

  public hidden: boolean;
  public id: number;
  public overallRating: number;
  public privateFeedback: string;
  public publicReview: string;
  public reviewResponse: string;
  public submitted: boolean;
  public reviewAnswerExpiredAt: Date;
  public reviewExpiredAt: Date;
  public isReviewAnswerAvailable: boolean;
  public headline: string;

  constructor(data: IReservationReview) {
    this.id = data?.id || null;
    this.hidden = data?.hidden || false;
    this.overallRating = data?.overall_rating || 0;
    this.privateFeedback = data?.private_feedback || null;
    this.publicReview = data?.public_review || null;
    this.reviewResponse = data?.reviewee_response || null;
    this.submitted = data?.submitted || false;
    this.reviewExpiredAt = data?.review_expired_at ? new Date(data?.review_expired_at) : null;
    this.reviewAnswerExpiredAt = data?.review_answer_expired_at
      ? new Date(data?.review_answer_expired_at)
      : null;
    this.isReviewAnswerAvailable =
      isBefore(new Date(), new Date(data?.review_answer_expired_at)) && !data?.reviewee_response;

    this.categoryRatings = {
      accuracy: data?.category_ratings?.accuracy ?? 0,
      checkin: data?.category_ratings?.checkin ?? 0,
      cleanliness: data?.category_ratings?.cleanliness ?? 0,
      communication: data?.category_ratings?.communication ?? 0,
      id: data?.category_ratings?.id ?? null,
      location: data?.category_ratings?.location ?? 0,
      respectHouseRules: data?.category_ratings?.respect_house_rules ?? 0,
      value: data?.category_ratings?.value ?? 0,
    };
    this.headline = data?.headline;
  }

  public get dto(): IReservationReview {
    return {
      category_ratings: {
        accuracy: this.categoryRatings?.accuracy || 0,
        checkin: this.categoryRatings?.checkin || 0,
        cleanliness: this.categoryRatings?.cleanliness || 0,
        communication: this.categoryRatings?.communication || 0,
        id: this.categoryRatings?.id || 0,
        location: this.categoryRatings?.location || 0,
        respect_house_rules: this.categoryRatings?.respectHouseRules || 0,
        value: this.categoryRatings?.value || 0,
      },
      hidden: this.hidden || false,
      id: this.id || null,
      overall_rating: this.overallRating || 0,
      private_feedback: this.privateFeedback || '',
      public_review: this.publicReview || '',
      reviewee_response: this.reviewResponse || '',
      submitted: this.submitted || false,
      review_answer_expired_at: this.reviewAnswerExpiredAt,
      headline: this.headline,
    };
  }
}

export class ReservationMetadata {
  public canPreApprove: boolean;
  public canSpecialOffer: boolean;
  public canDecline: boolean;
  public canWithdraw: boolean;
  public canAccept: boolean;
  public canWriteReview: boolean;
  public canRunAutoReview: boolean;
  public canViewCalendar: boolean;
  public typeTitle: string;
  public canCancel: boolean;
  public canCreateAlteration: boolean;
  public hasAlterationFromGuest: boolean;
  public statusTitle: EReservationStatusTitle;
  public colors: {
    text: string;
    background: string;
  };

  constructor(data: Pick<IReservation, 'metadata'>) {
    this.canAccept = data?.metadata?.can_accept;
    this.canDecline = data?.metadata?.can_decline;
    this.canCancel = data?.metadata?.can_cancel;
    this.canCreateAlteration = data?.metadata?.can_create_alteration || false;
    this.hasAlterationFromGuest = data?.metadata?.has_alteration_from_guest || false;
    this.canPreApprove = data?.metadata?.can_pre_approve;
    this.canRunAutoReview = data?.metadata?.can_run_auto_review;
    this.canSpecialOffer = data?.metadata?.can_special_offer;
    this.canViewCalendar = data?.metadata?.can_view_calendar;
    this.canWithdraw = data?.metadata?.can_withdraw;
    this.canWriteReview = data?.metadata?.can_write_review;
    this.typeTitle = data?.metadata?.type_title;
    this.statusTitle = data?.metadata?.status_title as EReservationStatusTitle;
    this.colors = {
      text: data?.metadata?.colors?.text,
      background: data?.metadata?.colors?.background,
    };
  }
}

export class ReservationAlteration {
  public id: string;
  public status: EAlterationStatus;
  public initiatedBy: 'guest' | 'host';
  public startDate: string;
  public endDate: string;
  public numberOfAdults: number;
  public numberOfChildren: number;
  public numberOfInfants: number;
  public numberOfPets: number;
  public totalPrice: number;
  public tax: number;
  public discount: number;
  public totalPerExtraGuests: number;
  public totalPerPets: number;
  public cleaningFee: number;
  public serviceFee: number;
  public paymentProcessingFee: number;
  public payoutTotalPrice: number;
  public listingId: number;

  constructor(data?: IReservationAlteration) {
    this.id = data?.id || null;
    this.status = data?.status || null;
    this.initiatedBy = data?.initiated_by || null;
    this.startDate = data?.start_date || null;
    this.endDate = data?.end_date || null;
    this.numberOfAdults = data?.number_of_adults || 0;
    this.numberOfChildren = data?.number_of_children || 0;
    this.numberOfInfants = data?.number_of_infants || 0;
    this.numberOfPets = data?.number_of_pets || 0;
    this.totalPrice = data?.total_price >= 0 ? data.total_price : null;
    this.tax = data?.tax >= 0 ? data.tax : null;
    this.discount = data?.discount >= 0 ? data.discount : null;
    this.totalPerExtraGuests =
      data?.total_per_extra_guests >= 0 ? data.total_per_extra_guests : null;
    this.totalPerPets = data?.total_per_pets >= 0 ? data.total_per_pets : null;
    this.cleaningFee = data?.cleaning_fee >= 0 ? data.cleaning_fee : null;
    this.serviceFee = data?.service_fee >= 0 ? data.service_fee : null;
    this.paymentProcessingFee =
      data?.payment_processing_fee >= 0 ? data.payment_processing_fee : null;
    this.payoutTotalPrice = data?.payout_total_price >= 0 ? data.payout_total_price : null;
    this.listingId = data?.listing?.id ?? null;
  }

  public get parsedCheckIn(): Date {
    return parse(this.startDate, FORMATTING_CONSTANTS.DATE_FORMAT, new Date());
  }

  public get parsedCheckOut(): Date {
    return parse(this.endDate, FORMATTING_CONSTANTS.DATE_FORMAT, new Date());
  }

  public get dto(): IReservationAlteration {
    return {
      id: this.id,
      status: this.status,
      initiated_by: this.initiatedBy,
      start_date: this.startDate,
      end_date: this.endDate,
      number_of_adults: this.numberOfAdults,
      number_of_children: this.numberOfChildren,
      number_of_infants: this.numberOfInfants,
      number_of_pets: this.numberOfPets,
      total_price: this.totalPrice,
      tax: this.tax,
      discount: this.discount,
      total_per_extra_guests: this.totalPerExtraGuests,
      total_per_pets: this.totalPerPets,
      cleaning_fee: this.cleaningFee,
      service_fee: this.serviceFee,
      payment_processing_fee: this.paymentProcessingFee,
      payout_total_price: this.payoutTotalPrice,
      listing: {
        id: this?.listingId,
      },
    };
  }
}

export class Reservation {
  public id: number;
  public expiredAt: string;
  public checkInDate: string;
  public checkOutDate: string;
  public checkInTime: number;
  public checkInTimeAsText: string;
  public checkOutTime: number;
  public checkOutTimeAsText: string;
  public lastAlteration: ReservationAlteration;
  public status: EReservationStatus;
  public paymentStatus: EReservationPaymentStatus;
  public type: EReservationType;
  public ignoreAutoReview: boolean;
  public isReviewAnswerAvailable: boolean;
  public isSendReviewAvailable: boolean;
  public requestedFrom: EChannel;
  public client: Client;
  public revenueSplit: RevenueSplit;
  public currency: string;
  public reviewExpiredAt: Date;
  public metadata: ReservationMetadata;
  public hasAutoReview: boolean;
  public propertyInfo: Pick<PropertyInfo, 'address' | 'lat' | 'lng' | 'name' | 'id'> & {
    image: string;
    listing: {
      id: number;
    };
  };
  public accounts: Client[];
  public createdAt: Date;
  public updatedAt: number;
  public guestCount: number;
  public petsCount: number;
  public childrenCount: number;
  public confirmCode: string;
  public feed: {
    id: number;
  };

  public hostReview: ReservationReview;
  public guestReview: ReservationReview;
  public specialOffer: SpecialOffer;
  public statusTitle: EReservationStatusTitle;
  public notes: string;
  public customCheckInTime: Date;
  public customCheckOutTime: Date;
  public parsedExpiredAt: Date;
  public listingId: number;

  constructor(data: Partial<IReservation>) {
    this.id = data?.id;
    this.expiredAt = data?.expired_at;
    this.checkInDate = data?.check_in_date;
    this.checkInTime = data?.check_in_time || null;
    this.checkInTimeAsText = data?.check_in_time_as_text || null;
    this.checkOutTime = data?.check_out_time || null;
    this.checkOutTimeAsText = data?.check_out_time_as_text || null;
    this.checkOutDate = data?.check_out_date;
    this.status = data?.status;
    this.type = data?.type;
    this.lastAlteration = data?.last_alteration
      ? new ReservationAlteration(data?.last_alteration)
      : null;
    this.ignoreAutoReview = data?.ignore_auto_review;
    this.client = new Client(data?.client);
    this.statusTitle = data?.status_title as EReservationStatusTitle;
    this.revenueSplit = new RevenueSplit(data?.revenue_split);
    this.requestedFrom = convertChannelFromDto(data.requested_from) || null;
    this.currency = data?.currency;
    this.paymentStatus = data?.payment_status;
    this.metadata = data.metadata ? new ReservationMetadata({ metadata: data.metadata }) : null;
    this.hasAutoReview = data?.has_auto_review;
    this.specialOffer = data?.active_special_offer
      ? new SpecialOffer(data?.active_special_offer)
      : null;
    this.propertyInfo =
      data?.property_info || data?.listing
        ? ({
            address: data?.property_info?.address,
            id: data?.property_info?.id,
            lat: data?.property_info?.lat,
            lng: data?.property_info?.lng,
            image: data?.property_info?.main_image?.image || data?.property_info?.image,
            name: data?.property_info?.name || data?.listing?.property_info?.name,
            listing: {
              id: (data?.property_info as any)?.listing?.id || data?.listing?.id,
            },
          } as any)
        : null;
    this.accounts = data?.accounts ? data.accounts.map((a) => new Client(a)) : null;
    const exp = data?.host_review?.review_expired_at;
    this.reviewExpiredAt = exp ? new Date(exp) : null;
    this.createdAt = data?.created_at ? new Date(data.created_at) : null;
    this.updatedAt = data?.updated_at;
    this.expiredAt = data?.expired_at;
    this.guestCount = data?.guest_count || 1;
    this.petsCount = data?.pets_count || 0;
    this.childrenCount = data?.children_count || 0;
    this.confirmCode = data?.confirm_code;
    this.feed = {
      id: data?.feed?.id,
    };
    this.hostReview = data?.host_review ? new ReservationReview(data?.host_review) : null;
    this.guestReview = data?.guest_review ? new ReservationReview(data?.guest_review) : null;
    this.notes = data?.notes || null;
    this.customCheckInTime = data?.custom_check_in_time
      ? new Date(data.custom_check_in_time)
      : null;
    this.customCheckOutTime = data?.custom_check_out_time
      ? new Date(data.custom_check_out_time)
      : null;
    this.parsedExpiredAt = this.expiredAt ? new Date(this.expiredAt) : null;
    this.listingId = data?.listing?.id ?? this.propertyInfo?.listing.id;
  }

  get total() {
    if (this.type === EReservationType.INQUIRY && this.isAirbnb) return this.revenueSplit.total;

    const subTotal = this.revenueSplit.subTotal - this.revenueSplit.serviceFee;

    if (this.isAirbnb) {
      return subTotal + this.revenueSplit.taxVat;
    }

    if (this.isVRBO) {
      return subTotal + this.revenueSplit.tax - this.revenueSplit.paymentProcessingFee;
    }

    if (this.isHosty) {
      return subTotal + this.revenueSplit.tax;
    }

    return this.revenueSplit.total;
  }

  get expiresIn() {
    if (!this.parsedExpiredAt) return 0;
    const diff = this.parsedExpiredAt.getTime() - Date.now();
    return Math.max(0, diff);
  }

  public get account(): Client {
    if (!this.accounts.length) return null;

    return this.accounts.length > 1
      ? this.accounts.find((a) => a.isAirbnb || a.isAirbnbSupport)
      : this.accounts[0];
  }

  get parsedReviewExpiredAt(): Date {
    return this.reviewExpiredAt;
  }

  get hasAlteration(): boolean {
    return (
      (this.statusTitle === EReservationStatusTitle.ALTERATION_REQUEST ||
        this.statusTitle === EReservationStatusTitle.ALTERATION_SEND ||
        this.statusTitle === EReservationStatusTitle.ALTERATION_PENDING) &&
      this.lastAlteration !== null
    );
  }

  get isExpired(): boolean {
    return this.status === EReservationStatus.EXPIRED;
  }

  get lastCheckIn(): Date {
    if (this.hasActiveSpecialOffer) {
      return this.specialOffer.parsedCheckInDate;
    }

    return this.parsedCheckInDate;
  }

  get alterationCheckIn(): Date {
    return this.hasAlteration ? this.lastAlteration.parsedCheckIn : null;
  }

  get fullGuestCount(): number {
    return this.guestCount + this.childrenCount + this.petsCount;
  }

  get lastGuestCount(): number {
    if (this.hasActiveSpecialOffer) {
      return this.specialOffer.guestCount;
    }
    return this.fullGuestCount;
  }

  get alterationGuestsCount(): number {
    return this.hasAlteration
      ? this.lastAlteration.numberOfAdults +
          this.lastAlteration.numberOfPets +
          this.lastAlteration.numberOfChildren +
          this.lastAlteration.numberOfInfants
      : null;
  }

  get hasSpecialOffer(): boolean {
    return this.statusTitle === EReservationStatusTitle.SPECIAL_OFFER;
  }

  get actualCheckInTime() {
    if (this.customCheckInTime) {
      return this.customCheckInTime.getUTCHours();
    }
    return this.checkInTime;
  }

  get actualCheckOutTime() {
    if (this.customCheckOutTime) {
      return this.customCheckOutTime.getUTCHours();
    }
    return this.checkOutTime;
  }

  get lastTotalPrice(): number {
    if (this.hasSpecialOffer) {
      return this.specialOffer?.totalPrice;
    }
    return this.total;
  }

  get alterationTotalPrice(): number {
    if (!this.hasAlteration) return null;
    const subTotal =
      this.lastAlteration.totalPrice +
      this.lastAlteration.cleaningFee +
      this.lastAlteration.totalPerPets +
      this.lastAlteration.discount +
      this.lastAlteration.totalPerExtraGuests -
      this.lastAlteration.serviceFee;

    if (this.isAirbnb) {
      return subTotal;
    }

    if (this.isVRBO) {
      return subTotal + this.lastAlteration.tax - this.lastAlteration.paymentProcessingFee;
    }

    return this.lastAlteration.totalPrice;
  }

  get lastListingId(): number {
    if (this.hasAlteration && this?.lastAlteration?.listingId) {
      return this?.lastAlteration?.listingId;
    }

    if (this.hasSpecialOffer && this?.specialOffer?.listingId) {
      return this?.specialOffer?.listingId;
    }

    return this.propertyInfo?.listing?.id;
  }

  get lastCheckOut(): Date {
    if (this.hasActiveSpecialOffer) {
      return this.specialOffer.parsedCheckOutDate;
    }
    return this.parsedCheckOutDate;
  }

  get alterationCheckOut(): Date {
    return this.hasAlteration ? this.lastAlteration.parsedCheckOut : null;
  }

  get rangeDays(): Array<{
    date: Date;
    isSelected: boolean;
    isSelectedStart: boolean;
    isSelectedEnd: boolean;
  }> {
    const days = new Array(this.daysAmount + 1).fill(this.parsedCheckInDate).map((d, i) => ({
      date: addDays(d, i),
      isSelected: true,
      isSelectedStart: i === 0,
      isSelectedEnd: false,
    }));
    days[days.length - 1].isSelectedEnd = true;

    return days;
  }

  get diffInDays(): number {
    return differenceInCalendarDays(new Date(this.checkOutDate), new Date(this.checkInDate));
  }

  get daysAmount(): number {
    return differenceInCalendarDays(this.lastCheckOut, this.lastCheckIn);
  }

  get alterationDaysAmount(): number {
    return this.hasAlteration
      ? differenceInCalendarDays(this.alterationCheckOut, this.alterationCheckIn)
      : null;
  }

  get parsedCheckInDate(): Date {
    if (!this.checkInDate) {
      return null;
    }
    return parse(this.checkInDate, FORMATTING_CONSTANTS.DATE_FORMAT, new Date());
  }

  get parsedCheckOutDate(): Date {
    if (!this.checkOutDate) {
      return null;
    }
    return parse(this.checkOutDate, FORMATTING_CONSTANTS.DATE_FORMAT, new Date());
  }

  public get isAirbnb(): boolean {
    return this.requestedFrom === 'airbnb';
  }

  public get isVRBO(): boolean {
    return this.requestedFrom === 'vrbo';
  }

  public get isHosty(): boolean {
    return this.requestedFrom === 'hosty';
  }

  public get hasActiveSpecialOffer(): boolean {
    return this.specialOffer && this.specialOffer.active;
  }

  public get canAccept(): boolean {
    const allowedStatuses = [
      EReservationStatusTitle.REQUEST_TO_BOOK,
      EReservationStatusTitle.ALTERATION_REQUEST,
      EReservationStatusTitle.RESERVATION_REQUEST,
    ];

    return allowedStatuses.includes(this.statusTitle);
  }

  public get canDecline(): boolean {
    const allowedStatuses = [
      EReservationStatusTitle.REQUEST_TO_BOOK,
      EReservationStatusTitle.ALTERATION_REQUEST,
      EReservationStatusTitle.RESERVATION_REQUEST,
    ];

    return allowedStatuses.includes(this.statusTitle);
  }

  public get canCancel(): boolean {
    const allowedStatuses = [EReservationStatusTitle.ACCEPTED_RESERVATION];

    return allowedStatuses.includes(this.statusTitle) && this.requestedFrom !== EChannel.Booking;
  }

  public get canEdit(): boolean {
    const allowedStatuses = [
      EReservationStatusTitle.ACCEPTED_RESERVATION,
      EReservationStatusTitle.RESERVATION_REQUEST,
    ];

    return allowedStatuses.includes(this.statusTitle) && this.requestedFrom !== EChannel.Booking;
  }

  public get canSpecialOffer(): boolean {
    const allowedStatuses = [
      EReservationStatusTitle.INQUIRY,
      EReservationStatusTitle.CANCELLED,
      EReservationStatusTitle.CANCELED,
      EReservationStatusTitle.CANCELLED_BY_AIRBNB,
      EReservationStatusTitle.DECLINED,
      EReservationStatusTitle.NOT_POSSIBLE,
      EReservationStatusTitle.EXPIRED_REQUEST,
      EReservationStatusTitle.OFFER_EXPIRED,
      EReservationStatusTitle.REQUEST_DECLINED,
      EReservationStatusTitle.PRE_APPROVED,
      EReservationStatusTitle.REQUEST_WITHDRAWN,
      EReservationStatusTitle.EXPIRED,
      EReservationStatusTitle.EXPIRED_INQUIRY,
      EReservationStatusTitle.EXPIRED_REQUEST,
      EReservationStatusTitle.REQUEST_DECLINED,
    ];

    return allowedStatuses.includes(this.statusTitle) && this.isAirbnb;
  }

  public get canWriteReview(): boolean {
    const allowedStatuses = [EReservationStatusTitle.REVIEW_GUEST];

    return allowedStatuses.includes(this.statusTitle) && !this.isReviewSubmitted && !this.hasAutoReview;
  }

  public get isReviewSubmitted(): boolean {
    return this.hostReview?.submitted || false;
  }

  public get canPreApprove(): boolean {
    const allowedStatuses = [EReservationStatusTitle.INQUIRY];

    return !this.isVRBO && allowedStatuses.includes(this.statusTitle);
  }

  public get canWithdraw(): boolean {
    const allowedStatuses = [
      EReservationStatusTitle.SPECIAL_OFFER,
      EReservationStatusTitle.ALTERATION_SEND,
      EReservationStatusTitle.PRE_APPROVED,
    ];

    return allowedStatuses.includes(this.statusTitle);
  }

  public get dto(): IReservation {
    return {
      guest_review: this.guestReview ? this.guestReview.dto : null,
      has_auto_review: this.hasAutoReview,
      has_special_offer: this.hasSpecialOffer,
      host_review: this.hostReview ? this.hostReview.dto : null,
      is_review_answer_available: this.isReviewAnswerAvailable || false,
      is_send_review_available: this.isSendReviewAvailable || false,
      last_alteration: this.lastAlteration ? this.lastAlteration.dto : null,
      requested_from: convertChannelToDto(this.requestedFrom) || null,
      active_special_offer: this.specialOffer ? this.specialOffer.dto : null,
      status_title: this.statusTitle || null,
      id: this.id || null,
      expired_at: this.expiredAt || null,
      check_in_date: this.checkInDate || null,
      check_out_date: this.checkOutDate || null,
      status: this.status || null,
      type: this.type || null,
      check_out_time: this.checkOutTime || null,
      check_out_time_as_text: this.checkOutTimeAsText || null,
      check_in_time: this.checkInTime || null,
      check_in_time_as_text: this.checkInTimeAsText || null,
      ignore_auto_review: this.ignoreAutoReview || null,
      client: this.client.dto || null,
      revenue_split: this.revenueSplit.dto || null,
      currency: this.currency || null,
      payment_status: this.paymentStatus || null,
      metadata: {
        can_accept: this.metadata?.canAccept || false,
        can_cancel: this.metadata?.canAccept || false,
        can_create_alteration: this.metadata?.canAccept || false,
        can_decline: this.metadata?.canDecline || false,
        can_pre_approve: this.metadata?.canPreApprove || false,
        can_run_auto_review: this.metadata?.canRunAutoReview || false,
        can_special_offer: this.metadata?.canSpecialOffer || false,
        can_view_calendar: this.metadata?.canViewCalendar || false,
        can_withdraw: this.metadata?.canWithdraw || false,
        can_write_review: this.metadata?.canWriteReview || false,
        colors: {
          text: this.metadata?.colors?.text,
          background: this.metadata?.colors?.background,
        },
        has_alteration_from_guest: this.metadata?.canAccept || false,
        status_title: this.metadata?.statusTitle || null,
        type_title: this.metadata?.typeTitle || null,
      },
      property_info: this.propertyInfo
        ? {
            id: this.propertyInfo?.id,
            address: this.propertyInfo?.address,
            lat: this.propertyInfo?.lat,
            lng: this.propertyInfo?.lng,
            image: this?.propertyInfo?.image,
            name: this?.propertyInfo?.name,
            listing_id: this.propertyInfo?.listing?.id,
          }
        : null,
      accounts: this.accounts ? this.accounts.map((a) => a.dto) || null : [],
      guest_count: this.guestCount || null,
      children_count: this.childrenCount || null,
      pets_count: this.petsCount || null,
      confirm_code: this.confirmCode || null,
      created_at: this.createdAt ? this.createdAt.toISOString() : null,
      updated_at: this.updatedAt || null,
      feed: {
        id: this.feed.id,
      },
      notes: this.notes || null,
      custom_check_out_time: this.customCheckOutTime
        ? format(this.customCheckOutTime, 'hh:mm:ss')
        : null,
      custom_check_in_time: this.customCheckInTime
        ? format(this.customCheckInTime, 'hh:mm:ss')
        : null,
    };
  }
}

export interface CancelReservationPayload {
  reason?: string;
  subReason?: string;
  cancelBy?: ECancelBy;
  messageToGuest: string;
  messageToAirbnb?: string;
  refundAmount?: number;
}

export interface DeclineReservationPayload {
  declineReason: string;
  declineMessage: string;
}

export interface CreateAlterationPayload {
  reservationId: number;
  startDate: Date;
  endDate: Date;
  numberOfAdults: number;
  numberOfChildren: number;
  numberOfPets?: number;
  numberOfInfants?: number;
  totalPrice: number;
  listingId: number;
  discount?: number;
  tax?: number;
  cleaningFee?: number;
  serviceFee?: number;
  totalPerExtraGuests?: number;
  totalPerPets?: number;
  deposit?: number;
}

export interface CreateReservationPayload {
  paymentStatus: EReservationPaymentStatus;
  guestEmail: string;
  guestPhone: string;
  guestFirstName: string;
  guestLastName: string;
  requestedFrom: string;
  calculatePayment: {
    range: Date[];
    numberOfAdults: number;
    numberOfChildren: number;
    numberOfPets: number;
    listingId: number;
  };
  listingPayment: {
    subTotal: number;
    cleaningFee: number;
    totalPerExtraGuests: number;
    totalPerPets: number;
    discount: number;
    tax: number;
  };
  sendInvoice: boolean;
  deposit: number;
}

export interface AddReservation {
  start_date: string;
  end_date: string;
  number_of_adults: number;
  number_of_children: number;
  number_of_pets: number;
  listing_id: number;
  total_price: number;
  discount: number;
  tax: number;
  cleaning_fee: number;
  total_per_extra_guests: number;
  total_per_pets: number;
  deposit: number;
  payment_status: EReservationPaymentStatus;
  guest_email: string;
  guest_phone: string;
  guest_first_name: string;
  guest_last_name: string;
  requested_from: string;
  send_invoice: boolean;
}

export const convertCreateAlterationPayload = (data: CreateAlterationPayload) => {
  return {
    reservation_id: data.reservationId,
    start_date: convertDateToDto(data.startDate),
    end_date: convertDateToDto(data.endDate),
    number_of_adults: data.numberOfAdults,
    number_of_children: data.numberOfChildren,
    number_of_infants: data.numberOfInfants,
    number_of_pets: data.numberOfPets,
    total_price: data.totalPrice,
    listing_id: data.listingId,
    discount: data.discount,
    tax: data.tax,
    cleaning_fee: data.cleaningFee,
    service_fee: data.serviceFee,
    total_per_extra_guests: data.totalPerExtraGuests,
    total_per_pets: data.totalPerPets,
    deposit: data.deposit,
  };
};

export const convertCreateReservationsPayload = ({
  paymentStatus,
  guestEmail,
  guestPhone,
  guestFirstName,
  guestLastName,
  requestedFrom,
  calculatePayment,
  listingPayment,
  deposit,
  sendInvoice,
}: Partial<CreateReservationPayload>): AddReservation => ({
  start_date: calculatePayment?.range.length
    ? format(calculatePayment?.range[0], FORMATTING_CONSTANTS.DATE_FORMAT)
    : null,
  end_date: calculatePayment?.range.length
    ? format(calculatePayment?.range[1], FORMATTING_CONSTANTS.DATE_FORMAT)
    : null,
  number_of_adults: calculatePayment.numberOfAdults,
  number_of_children: calculatePayment.numberOfChildren,
  number_of_pets: calculatePayment.numberOfPets,
  listing_id: calculatePayment.listingId,
  total_price: listingPayment.subTotal,
  discount: listingPayment.discount,
  tax: listingPayment.tax,
  cleaning_fee: listingPayment.cleaningFee,
  total_per_extra_guests: listingPayment.totalPerExtraGuests,
  total_per_pets: listingPayment.totalPerPets,
  deposit: deposit,
  payment_status: paymentStatus,
  guest_email: guestEmail,
  guest_phone: guestPhone,
  guest_first_name: guestFirstName,
  guest_last_name: guestLastName,
  requested_from: requestedFrom,
  send_invoice: sendInvoice,
});

export interface CheckInOutReservation {
  id: number;
  checkIn: Date;
  checkOut: Date;
  type: 'ical' | 'reservation';
  channel?: string;
  listing: {
    id: number;
    name: string;
    address: string;
    image: string;
  };
  user: {
    avatar: string;
    firstName: string;
    lastName: string;
    type: string;
  };
}

export const convertDashboardReservationFromDto = (
  dto: ICheckInOutReservation,
): CheckInOutReservation => ({
  id: dto.id,
  checkIn: convertDateFromDto(dto.check_in_date),
  checkOut: convertDateFromDto(dto.check_out_date),
  type: dto.type,
  channel: dto.channel === 'homeaway' ? 'vrbo' : dto.channel,
  listing: dto.property_info.listing && {
    id: dto.property_info.listing.id,
    name: dto.property_info.name,
    address: dto.property_info.address,
    image: dto.property_info.main_image?.extra_medium_url ?? null,
  },
  user: dto.client && {
    firstName: dto.client.first_name,
    lastName: dto.client.last_name,
    avatar: dto.client.avatar,
    type: dto.client.type === 'homeaway' ? 'vrbo' : dto.client.type,
  },
});
