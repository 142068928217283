import { EAmountType, EChannel, EFeeType, EPriceRuleType } from '../enums';
import {
  IListingExpectationForGuests,
  IPermit,
  IPropertyAmenity,
  IPropertyInfo,
  IPropertyInfoImage,
  IStandardFee,
} from '../interfaces';
import { IDefaultPricingRule } from '../interfaces/property-info.interface';

import { convertRoomFromDto, Room } from './room';

export type InstantBookingCategory =
  | 'everyone'
  | 'off'
  | 'experienced'
  | 'experienced_guest_with_government_id'
  | 'government_id';

export class StandardFee {
  feeType: EFeeType;
  amountType: EAmountType;
  amount: number | null;

  constructor(data: IStandardFee) {
    this.feeType = data.fee_type;
    this.amountType = data.amount_type;
    this.amount = data.amount;
  }
}

export class PropertyAmenity {
  value: string;
  description: string;

  constructor(data: IPropertyAmenity) {
    this.value = data.value;
    this.description = data.description || null;
  }
}

export class DefaultPricingRule {
  ruleType: EPriceRuleType;
  priceChange: number;
  priceChangeType: 'PERCENT' | 'ABSOLUTE';
  thresholdOne: number;

  constructor(data?: IDefaultPricingRule) {
    this.ruleType = data?.rule_type || null;
    this.priceChange = data?.price_change || 0;
    this.priceChangeType = data?.price_change_type || 'PERCENT';
    this.thresholdOne = data?.threshold_one || 0;
  }
}

export class PropertyInfoImage {
  caption: string | null;
  id: number;
  image: string;
  main: boolean;
  ordering: number;
  width: number;
  height: number;

  constructor(data: IPropertyInfoImage) {
    this.id = data.id;
    this.caption = data.caption;
    this.ordering = data.ordering;
    this.image = data.image;
    this.main = data.main;
    this.width = data.width || 0;
    this.height = data.height || 0;
  }
}

export class ListingExpectationForGuests {
  type: string;
  addedDetails: string;

  constructor(data: IListingExpectationForGuests) {
    this.type = data.type;
    this.addedDetails = data.added_details || null;
  }
}

export interface PriceMarkup {
  channel: EChannel;
  value: number;
}

export class PropertyInfo {
  id: number;
  name: string;
  amenities: Array<PropertyAmenity>;
  propertyTypeGroup: string;
  propertyTypeCategory: string;
  postalCode: string;
  tax: number;
  address: string;
  apt: string;
  bathrooms: number;
  bedrooms: number;
  beds: number;
  bookingWindow: number;
  checkInTimeEnd: string;
  checkInTimeStart: number;
  checkOutTime: number;
  city: string;
  countryCode: string;
  hoursBeforeBooking: number;
  houseRules: string;
  instantBookingAllowedCategory: InstantBookingCategory;
  interaction: string;
  lat: number;
  description: string;
  notes: string;
  listingId: number;
  lng: number;
  maxNights: number;
  minNights: number;
  neighborhoodOverview: string;
  personCapacity: number;
  roomTypeCategory: string;
  space: string;
  state: string;
  access: string;
  directions: string;
  street: string;
  summary: string;
  summaryVrbo: string;
  unitName: string;
  transit: string;
  area: number;
  areaUnit: string;
  image: string;
  images: Array<PropertyInfoImage>;
  mainImage: {
    image: string;
    extraMediumUrl: string;
  };
  houseManual: string;
  registrationNumber: string;
  checkInOption: {
    category: string;
    instruction: string;
  };
  cancellationPolicy: string;
  longTermCancellationPolicy: string;
  cancellationPolicyVrbo: string;
  instantBookWelcomeMessage: string;
  guestControls: {
    allowsChildrenAsHost: boolean;
    allowsEventsAsHost: boolean;
    allowsInfantsAsHost: boolean;
    allowsPetsAsHost: boolean;
    allowsSmokingAsHost: boolean;
    childrenNotAllowedDetails: string;
    infantsNotAllowedDetails: string;
  };
  bathroomsVrbo: number;
  listingExpectationsForGuests: ListingExpectationForGuests[];
  allowRtbAboveMaxNights: boolean;
  turnoverDays: number;
  dayOfWeekCheckIn: Array<{ dayOfWeek: number }>;
  dayOfWeekCheckOut: Array<{ dayOfWeek: number }>;
  listingPrice: number;
  listingPriceMarkups: PriceMarkup[];
  listingPriceVrbo: number;
  listingPriceVrboPercent: number;
  listingPriceAirbnbPercent: number;
  cleaningFee: number;
  weekendPrice: number;
  weekendPriceMarkups: PriceMarkup[];
  weekendPriceVrbo: number;
  weekendPriceAirbnbPercent: number;
  weekendPriceVrboPercent: number;
  weeklyPriceFactor: number | null;
  monthlyPriceFactor: number | null;
  guestsIncluded: number;
  securityDeposit: number;
  pricePerPet: number;
  propertyType: string;
  pricePerExtraPerson: number;
  defaultPricingRules: DefaultPricingRule[];
  bookingPolicy: string;
  minimumAge: number | string;
  minimumAgeNote: string;
  standardFees: Array<StandardFee>;
  agreementFile: { id: string; url: string; originalName: string };
  agreementUrl: string;
  permits: IPermit[];
  rooms?: Room[];
  remoteIdAirbnb?: string;
  vrboUrl: string | null;

  constructor(data: Partial<IPropertyInfo>, channels: EChannel[]) {
    this.id = data.id || null;
    this.name = data.name || null;
    this.propertyTypeGroup = data.property_type_group || null;
    this.propertyTypeCategory = data.property_type_category || null;
    this.address = data.address || null;
    this.apt = data.apt || null;
    this.agreementFile = data?.agreement_file
      ? { ...data.agreement_file, originalName: data.agreement_file.original_name }
      : null;
    this.agreementUrl = data?.agreement_url || null;
    this.bathrooms = data.bathrooms || null;
    this.bedrooms = data.bedrooms || null;
    this.defaultPricingRules = data?.default_pricing_rules
      ? data?.default_pricing_rules.map((r) => new DefaultPricingRule(r))
      : [];
    this.beds = data.beds || 0;
    this.images = data.images
      ? [...data.images]
          .sort((img1, img2) => img1.ordering - img2.ordering)
          .map((img, i) => ({
            ...img,
            main: i === 0,
            ordering: i + 1,
          }))
      : [];
    this.propertyType = data?.property_type || null;
    this.postalCode = data.zipcode || null;
    this.permits = data.permits;
    this.bookingWindow = data.booking_window || 0;
    this.checkInTimeEnd =
      data.check_in_time_end && data.check_in_time_end !== 'NOT_SELECTED'
        ? data.check_in_time_end
        : null;
    this.checkInTimeStart =
      data.check_in_time_start && data.check_in_time_start !== 'NOT_SELECTED'
        ? data.check_in_time_start
        : null;
    this.checkOutTime = data.check_out_time || null;
    this.city = data.city || null;
    this.countryCode = data.country_code || null;
    this.description = data.description || null;
    this.hoursBeforeBooking = data.hours_before_booking || 0;
    this.houseRules = data.house_rules || null;
    this.instantBookingAllowedCategory = [
      'off',
      'everyone',
      'guests_with_verified_identity',
      'well_reviewed_guests',
      'well_reviewed_guests_with_verified_identity',
    ].includes(data.instant_booking_allowed_category ?? '')
      ? data.instant_booking_allowed_category
      : 'off';
    this.instantBookWelcomeMessage = data.instant_book_welcome_message || null;
    this.interaction = data.interaction || null;
    this.lat = data.lat || null;
    this.access = data.access || null;
    this.lng = data.lng || null;
    this.minimumAge = data?.minimum_age || '';
    this.minimumAgeNote = data?.minimum_age_note || '';
    this.maxNights = data.max_nights || null;
    this.minNights = data.min_nights || null;
    this.neighborhoodOverview = data.neighborhood_overview || null;
    this.personCapacity = data.person_capacity || null;
    this.propertyTypeGroup = data.property_type_group || null;
    this.roomTypeCategory = data.room_type_category || null;
    this.space = data.space || null;
    this.state = data.state || null;
    this.street = data.street || null;
    this.tax = data.tax || null;
    this.summary = data.summary || null;
    this.summaryVrbo = data.summary_vrbo || null;
    this.unitName = data.unit_name || null;
    this.area = data.area || 0;
    this.areaUnit = data?.area_unit || 'SQUARE_FEET';
    this.transit = data.transit || null;
    this.bookingPolicy = data.booking_policy || null;
    this.notes = data.notes || null;

    this.mainImage = data?.main_image
      ? {
          image: data?.main_image?.image,
          extraMediumUrl: data?.main_image?.extra_medium_url,
        }
      : null;

    this.directions = data.directions || null;
    this.houseManual = data.house_manual || null;
    this.registrationNumber = data.registration_number
      ? data.registration_number
      : this.permits?.length
      ? this.permits.find((p) => !!p.current_flow_slug && p.regulation_type === 'registration')
          ?.finalized_answers?.permit_number?.text_value ?? null
      : null;
    this.checkInOption = {
      category: data?.check_in_option?.category || null,
      instruction: data?.check_in_option?.instruction || null,
    };
    this.amenities = data.amenities || null;
    this.cancellationPolicy = data.cancellation_policy || null;
    this.guestControls = {
      allowsChildrenAsHost: data?.guest_controls?.allows_children_as_host ?? null,
      allowsEventsAsHost: data?.guest_controls?.allows_events_as_host ?? null,
      allowsInfantsAsHost: data?.guest_controls?.allows_infants_as_host ?? null,
      allowsPetsAsHost: data?.guest_controls?.allows_pets_as_host ?? null,
      allowsSmokingAsHost: data?.guest_controls?.allows_smoking_as_host ?? null,
      childrenNotAllowedDetails: data?.guest_controls?.children_not_allowed_details ?? null,
      infantsNotAllowedDetails:
        data?.guest_controls?.infants_not_allowed_details ??
        data?.guest_controls?.children_not_allowed_details ??
        null,
    };
    this.bathroomsVrbo = data?.bathrooms_vrbo || 1;
    this.cancellationPolicyVrbo = data?.cancellation_policy_vrbo || null;
    this.listingExpectationsForGuests =
      this.mapListingExpectations(data?.listing_expectations_for_guests) || null;
    this.allowRtbAboveMaxNights = data.allow_rtb_above_max_nights;
    this.turnoverDays = data?.turnover_days || 0;
    this.dayOfWeekCheckIn = data?.day_of_week_check_in
      ? data.day_of_week_check_in.map((d) => ({ dayOfWeek: d.day_of_week }))
      : [];
    this.dayOfWeekCheckOut = data?.day_of_week_check_out
      ? data.day_of_week_check_out.map((d) => ({ dayOfWeek: d.day_of_week }))
      : [];
    this.listingPrice = data?.listing_price;
    this.listingPriceMarkups = [];
    this.listingPriceVrbo = data?.listing_price_vrbo;
    this.listingPriceVrboPercent = data?.listing_price_vrbo_percent ?? 0;
    this.listingPriceAirbnbPercent = data?.listing_price_airbnb_percent ?? 0;

    if (channels.includes(EChannel.Airbnb)) {
      this.listingPriceMarkups.push({
        channel: EChannel.Airbnb,
        value: this.listingPriceAirbnbPercent,
      });
    }
    if (channels.includes(EChannel.Vrbo)) {
      this.listingPriceMarkups.push({
        channel: EChannel.Vrbo,
        value: this.listingPriceVrboPercent,
      });
    }

    this.cleaningFee = data?.cleaning_fee ?? null;
    this.weekendPrice = data?.weekend_price;
    this.weekendPriceMarkups = [];
    this.weekendPriceVrbo = data?.weekend_price_vrbo;
    this.weekendPriceVrboPercent = data?.weekend_price_vrbo_percent;
    this.weekendPriceAirbnbPercent = data?.weekend_price_airbnb_percent;

    if (channels.includes(EChannel.Airbnb)) {
      this.weekendPriceMarkups.push({
        channel: EChannel.Airbnb,
        value: this.weekendPriceAirbnbPercent,
      });
    }
    if (channels.includes(EChannel.Vrbo)) {
      this.weekendPriceMarkups.push({
        channel: EChannel.Vrbo,
        value: this.weekendPriceVrboPercent,
      });
    }

    this.weeklyPriceFactor = data?.weekly_price_factor ?? null;
    this.monthlyPriceFactor = data?.monthly_price_factor;
    this.guestsIncluded = data?.guests_included || 1;
    this.securityDeposit = data?.security_deposit;
    this.pricePerExtraPerson = data?.price_per_extra_person;
    this.standardFees =
      data?.standard_fees && Array.isArray(data?.standard_fees)
        ? this.setStandardFees(data?.standard_fees)
        : [];
    this.rooms = data?.rooms && data.rooms.map(convertRoomFromDto);
    this.longTermCancellationPolicy =
      data?.long_term_cancellation_policy || 'CANCEL_LONG_TERM_FAIR';
    this.remoteIdAirbnb = data?.remote_id;
    this.vrboUrl = data?.url_vrbo?.includes('https')
      ? data.url_vrbo
      : data?.url_vrbo
      ? 'https://' + data.url_vrbo
      : null;
  }

  get smallImage(): string | null {
    return this.mainImage?.extraMediumUrl || null;
  }

  get inversedCheckInDays(): Array<{ dayOfWeek: number }> {
    const days = [0, 1, 2, 3, 4, 5, 6];

    return days
      .filter((d) => !this.dayOfWeekCheckIn.map((d) => d.dayOfWeek).includes(d))
      .map((d) => ({ dayOfWeek: d }));
  }

  get inversedCheckOutDays(): Array<{ dayOfWeek: number }> {
    const days = [0, 1, 2, 3, 4, 5, 6];

    return days
      .filter((d) => !this.dayOfWeekCheckOut.map((d) => d.dayOfWeek).includes(d))
      .map((d) => ({ dayOfWeek: d }));
  }

  setStandardFees(fees: IStandardFee[]): StandardFee[] {
    return fees.map((fee) => ({
      amount: fee.amount
        ? fee.amount_type.toLowerCase() === 'flat'
          ? fee.amount / 1000000
          : fee.amount
        : null,
      amountType: (fee.amount_type as string).toLowerCase() as EAmountType,
      feeType: fee.fee_type,
    }));
  }

  mapListingExpectations(
    items: IListingExpectationForGuests[] = [],
  ): ListingExpectationForGuests[] {
    if (items && items.length) {
      return items.map<ListingExpectationForGuests>((i) => new ListingExpectationForGuests(i));
    }

    return [];
  }

  get requiredLicence(): boolean {
    return this.permits?.length > 0;
  }

  get advanceNotice(): number {
    return this.hoursBeforeBooking === 0 ? -1 : this.hoursBeforeBooking;
  }
}
