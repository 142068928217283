import { ICalType } from '@hosty-app/types/enums';

import {AccountDto, ChannelStatusDto} from '@hosty-web/interfaces';

import { IPropertyInfo, IStandardFee } from './property-info.interface';

export interface ListingMetadataDto {
  can_listed: boolean;
  has_auto_price: boolean;
  has_website: boolean;
  required_tab: 'general' | 'description' | 'photo' | 'booking' | 'availability' | 'pricing' | null;
}

export interface IListingICal {
  id: number;
  type: ICalType;
  url: string;
  title: string;
  status: string;
  syncAt?: string;
  logo?: string;
  listing?: { id: number };
}

export interface ISeasonalPriceDto {
  id: string;
  month_from: number;
  day_from: number;
  month_to: number;
  day_to: number;
  price: number;
  price_vrbo: number;
  vrbo_percent: number;
  price_weekend: number;
  price_vrbo_weekend: number;
  vrbo_percent_weekend: number;
}

export interface IListing {
  id: number;
  accounts: AccountDto[];
  publish_airbnb: boolean;
  capability_types_testricted: string;
  currency: string;
  connect_airbnb: boolean;
  connect_vrbo: boolean;
  listing_approval_status_notes: string;
  airbnb_approval_status: string;
  last_sync_i_cal_at: string;
  metadata: ListingMetadataDto;
  nickname: string;
  parent: IListing;
  i_cal: IListingICal[];
  property_info: Partial<IPropertyInfo>;
  hash?: string;
  vrbo_hash?: string;
  status: string;
  publish_vrbo: boolean;
  tags: Array<{ id: string; title: string }>;
  connect_price_labs?: boolean;
  price_labs_last_sync_at?: string;
  price_labs_error?: string;
  seasonal_prices: ISeasonalPriceDto[];
  position?: number;
  channels?: AccountDto[];
  auto_change_min_nights?: boolean;
  apply_markup_for_seasonal_price?: boolean;
  task_minutes?: number;
  auto_create_task_for_checkout?: boolean;
  group: {
    id: string;
  } | null;
  has_parent: boolean;
  connect_wheel_house: boolean;
  wheel_house_last_sync_at?: string;
  hosty_approval_status: string;
  publish_hosty: boolean;
  vrbo_approval_status: string;
  vrbo: ChannelStatusDto;
}

export interface ICreateListing {
  headline: string;
  description: string;
  property_name: string;
  tax: number;
  address: string;
  area: number;
  area_unit: string;
  street: string;
  minimum_age: number;
  minimum_age_note: string;
  city: string;
  space: string;
  state: string;
  country: string;
  booking_policy: string;
  postal_code: string;
  latitude: number;
  longitude: number;
  bathrooms: number;
  bedrooms: number;
  property_type: string;
  currency: string;
  registration_number: string;
  step: string;
  action: string;
  summary: string;
  summary_vrbo: string;
  unit_name: string;
  access: string;
  interaction: string;
  notes: string;
  neighborhood_overview: string;
  cancellation_policy: string;
  long_term_cancellation_policy: string;
  agreement_file_id: string;
  agreement_url: string;
  check_in_time_start: string;
  check_in_time_end: string;
  check_out_time: number;
  instant_booking_allowed_category: string;
  instant_book_welcome_message: string;
  property_type_group: string;
  property_type_category: string;
  room_type_category: string;
  directions: string;
  house_manual: string;
  apt: string;
  beds: number;
  person_capacity: number;
  enable_price_labs: boolean;
  price_vrbo_type: 'flat' | 'percent';
  listing_price: number;
  listing_price_vrbo: number;
  listing_price_vrbo_percent: number;
  default_pricing_rules: unknown[];
  weekend_price: number;
  weekend_price_vrbo: number;
  weekend_price_vrbo_percent: number;
  security_deposit: number;
  cleaning_fee: number;
  guests_included: number;
  price_per_extra_person: number;
  hours_before_booking: number;
  allow_request_to_book: number;
  min_nights: number;
  max_nights: number;
  booking_window: number;
  weekly_price_factor: number;
  monthly_price_factor: number;
  allow_rtb_above_max_nights: boolean;
  turnover_days: number;
  parent_id: number;
  amenities: Array<{ value: string; description: string }>;
  cancellation_policy_vrbo: string;
  guest_controls: {
    allows_children_as_host: boolean;
    allows_infants_as_host: boolean;
    allows_pets_as_host: boolean;
    allows_smoking_as_host: boolean;
    allows_events_as_host: boolean;
    children_not_allowed_details: string;
  };
  standard_fees: Array<IStandardFee>;
  check_in_option: {
    category: string;
    instruction: string;
  };
  day_of_week_check_in: Array<{
    day_of_week: number;
  }>;
  day_of_week_check_out: Array<{
    day_of_week: number;
  }>;
  listing_expectations_for_guests: Array<{
    type: string;
    added_details: string;
  }>;
  images: Array<
    {
      ordering: number;
      caption: string | null;
      category?: string | null;
      room_id?: string | null;
      amenity?: string | null;
    } & ({ id: number } | { file_id: string })
  >;
  tags: Array<{
    id: number;
    title: string;
  }>;
  account_ids: number[];
  bathrooms_vrbo: number;
  seasonal_prices: ISeasonalPriceDto[];
  auto_change_min_nights: boolean;
  apply_markup_for_seasonal_price: boolean;
  task_minutes: number;
  auto_create_task_for_checkout: boolean;
  group_id: string | null;
  enable_wheel_house: boolean;
  remove_seasonal_price_ids: string[];
}

export type IListingPricing = Pick<
  ICreateListing,
  | 'enable_price_labs'
  | 'currency'
  | 'listing_price'
  | 'listing_price_vrbo'
  | 'listing_price_vrbo_percent'
  | 'weekend_price'
  | 'weekend_price_vrbo'
  | 'weekend_price_vrbo_percent'
  | 'tax'
  | 'security_deposit'
  | 'cleaning_fee'
  | 'guests_included'
  | 'price_per_extra_person'
  | 'weekly_price_factor'
  | 'monthly_price_factor'
  | 'standard_fees'
  | 'default_pricing_rules'
  | 'seasonal_prices'
  | 'apply_markup_for_seasonal_price'
  | 'enable_wheel_house'
  | 'remove_seasonal_price_ids'
>;

export type IListingAvailability = Pick<
  ICreateListing,
  | 'hours_before_booking'
  | 'allow_request_to_book'
  | 'booking_window'
  | 'turnover_days'
  | 'allow_rtb_above_max_nights'
  | 'min_nights'
  | 'max_nights'
  | 'day_of_week_check_in'
  | 'day_of_week_check_out'
  | 'auto_change_min_nights'
>;

export type IListingAmenities = Pick<ICreateListing, 'amenities'>;

export type IListingBookings = Pick<
  ICreateListing,
  | 'cancellation_policy'
  | 'long_term_cancellation_policy'
  | 'cancellation_policy_vrbo'
  | 'booking_policy'
  | 'check_in_time_start'
  | 'check_in_time_end'
  | 'check_out_time'
  | 'instant_booking_allowed_category'
  | 'instant_book_welcome_message'
  | 'allow_request_to_book'
  | 'guest_controls'
  | 'listing_expectations_for_guests'
  | 'agreement_file_id'
  | 'minimum_age'
  | 'minimum_age_note'
  | 'agreement_url'
>;

export type IListingPhoto = Pick<ICreateListing, 'images'>;

export type IListingDescription = Pick<
  ICreateListing,
  | 'description'
  | 'summary'
  | 'summary_vrbo'
  | 'unit_name'
  | 'access'
  | 'interaction'
  | 'notes'
  | 'space'
  | 'neighborhood_overview'
>;

export interface IListingRooms {
  rooms: {
    id: string;
    account_type: 'airbnb' | 'homeaway';
    name: string;
    note: string;
    room_type: string;
    room_sub_type: string;
    is_private: boolean;
    beds: {
      type: string;
      quantity: number;
    }[];
    room_amenities: {
      type: string;
      quantity: number;
    }[];
  }[];
}

export interface IListingGeneral
  extends Pick<
    ICreateListing,
    | 'property_name'
    | 'address'
    | 'street'
    | 'city'
    | 'state'
    | 'country'
    | 'postal_code'
    | 'area'
    | 'area_unit'
    | 'latitude'
    | 'longitude'
    | 'bathrooms'
    | 'bathrooms_vrbo'
    | 'bedrooms'
    | 'property_type'
    | 'registration_number'
    | 'property_type_group'
    | 'property_type_category'
    | 'room_type_category'
    | 'directions'
    | 'house_manual'
    | 'apt'
    | 'beds'
    | 'person_capacity'
    | 'parent_id'
    | 'check_in_option'
    | 'tags'
    | 'account_ids'
    | 'task_minutes'
    | 'auto_create_task_for_checkout'
    | 'group_id'
  > {
  nickname: string;
}

export enum EListingPart {
  GENERAL = 'general',
  ROOMS = 'rooms',
  PRICING = 'pricing',
  PHOTO = 'photo',
  BOOKING = 'booking',
  AMENITIES = 'amenities',
  DESCRIPTION = 'description',
  AVAILABILITY = 'availability',
}
